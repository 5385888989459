<template>
  <app-layout>
    <v-container>
      <user-tabs current="cereri" />
      <page-section>
        <loader v-if="loading" class="my-5" />

        <template v-else>
          <template v-if="totalRows">
            <rfqs-index-table :rows="rows" />
            <pagination
              @change:page="index($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>
          <no-results v-else class="m-5" />
        </template>
      </page-section>
    </v-container>
  </app-layout>
</template>

<script>
import PageSection from "../components/PageSection.vue";
import VContainer from "../components/VContainer.vue";
import AppLayout from "../layout/AppLayout.vue";
import rfqsApi from "../api/resources/rfqsApi";
import RfqsIndexTable from "../components/RfqsIndexTable.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";

import Pagination from "../components/Pagination.vue";
import UserTabs from "../components/UserTabs.vue";

export default {
  components: {
    AppLayout,
    PageSection,
    VContainer,
    RfqsIndexTable,
    Loader,
    NoResults,
    Pagination,
    UserTabs,
  },
  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: false,
      query: {},
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = 1) {
      this.loading = true;
      rfqsApi
        .index({ page, ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = true;
        });
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
